<template>
  <v-container class="edit-container px-10" fluid>
    <v-popup-loading :isLoading="isLoading" />
    <snack-bar
      v-model="isCreateFail"
      :content="snackbarFail"
      type="error"
    ></snack-bar>
    <alert-confirm
      :show="isCancel"
      title="Discard changes"
      subTitle="Are you sure you want to discard all changes?"
      headline-color="black"
      buttonCancel="OK"
      buttonText="Cancel"
      :close="onCancel"
      :confirm="closeCancelPopup"
      icon=""
    ></alert-confirm>
    <alert-confirm
      :show="isSave"
      title="Error"
      subTitle="Start time and end time are required."
      headline-color="black"
      buttonText="OK"
      :showBtnCancel="false"
      :confirm="closeValiationMsg"
      confirm-btn-color="info600Color"
      icon=""
    ></alert-confirm>
    <v-row class="mb-4">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row class="align-center mr-0" style="margin-left: 1px">
          <h3 class="pageHeadline" t-data="page-headline">
            Schedule Medical Center
          </h3>
          <v-spacer></v-spacer>
          <edit-buttons
            :show-remove="false"
            :show-preview="false"
            :show-save="true"
            :on-cancel="showCancelPopup"
            :on-save="onSave"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-form ref="form" lazy-validation>
      <v-card class="mt-5" v-for="(dayOfWeek, i) in schedules" :key="i">
        <template v-if="i == 0">
          <v-card-title class="mt-0 pt-0 pb-0" t-data="card-title">
            <h6 class="mb-0 card-title" t-data="info-title">
              Schedule Information
            </h6>
          </v-card-title>
          <v-divider></v-divider>
        </template>

        <v-card-title class="mt-0 pt-0 pb-0" t-data="card-title">
          <h6 class="mb-0 card-title" t-data="info-title">
            {{ getDaysOfWeek(i) }}
          </h6>
        </v-card-title>
        <v-card-text class="mt-0">
          <TimeSlot
            v-for="(schedule, index) in dayOfWeek"
            :key="index"
            :timeslotObj="schedule"
            :removeSchedule="() => removeSchedule(i, index)"
            :addNewSchedule="() => addNewSchedule(i)"
          ></TimeSlot>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {mapState, mapActions} from 'vuex'
import AlertConfirm from '@/components/AlertConfirm'
import TimeSlot from '@/components/TimeSlot'
import {hasPermissionsByOrgs, snackbarFailContent} from '@/helper/helper'
import EditButtons from '@/components/EditButtons'
import SnackBar from '@/components/SnackBar'
import PopupLoading from '@/components/PopupLoading'

export default {
  components: {
    EditButtons,
    AlertConfirm,
    TimeSlot,
    SnackBar,
    'v-popup-loading': PopupLoading
  },
  name: 'Schedules',
  data() {
    return {
      linkroute: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'departments',
          disabled: false,
          href: '/department-list'
        },
        {
          text: this.textMode,
          disabled: true,
          href: '/'
        }
      ],
      isError: false,
      isCancel: false,
      isCreateSuccess: false,
      isSave: false,
      isCreateFail: false,
      snackTitle: 'added department schedules',
      pageName: ''
    }
  },
  computed: {
    snackbarFail() {
      return snackbarFailContent(this.pageName, this.snackTitle)
    },
    breadcrumbs() {
      return [
        {text: 'Dashboard', links: true, to: '/'},
        {text: 'Departments', links: true, to: '/departments'}
      ]
    },
    ...mapState('department', [
      'isLoading',
      'departmentObject',
      'schedules',
      'mondaySchedules',
      'thuedaySchedules',
      'wednesdaySchedules',
      'thursdaySchedules',
      'fridaySchedules',
      'saturdaySchedules',
      'sundaySchedules'
    ])
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.resetState()
  },
  async created() {},
  mounted() {
    this.fetchDetails()
  },
  watch: {},
  methods: {
    getDaysOfWeek(i) {
      const days = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ]
      return days[i] || 'Invalid day' // Handles invalid indices
    },
    removeSchedule(dayOfWeek, index) {
      this.schedules[dayOfWeek].splice(index, 1)
    },
    addNewSchedule(i) {
      this.schedules[i].map(obj => (obj.is_final = false))
      this.schedules[i].push({
        id: i + 1 + '_' + (this.schedules[i].length + 1),
        start_time: '',
        end_time: '',
        remark: '',
        tooltip: '',
        is_final: true
      })
    },
    async fetchDetails() {
      await this.setDepartmentId(this.$route.params.id)
      await this.setSchedules(this.$route.params.id)
      const isViewOnly = !hasPermissionsByOrgs('CanEditDepartment', [
        this.departmentObject.org_codename
      ])
      await this.setOrgInfo(isViewOnly)
    },
    hasPermDelete() {
      return hasPermissionsByOrgs('CanDeleteDepartment', [
        this.departmentObject.org_codename
      ])
    },
    showCancelPopup() {
      this.isCancel = true
    },
    closeCancelPopup() {
      this.isCancel = false
    },
    closeValiationMsg() {
      this.isSave = false
    },
    onCancel() {
      this.$router.push({
        name: 'department-list'
      })
    },
    async onSave() {
      const formatedSchedules = this.schedules
        .map((days, index) =>
          days
            .map(time => {
              time.day_of_week = index + 1
              time.id = isNaN(time.id) ? 0 : time.id
              return time
            })
            .filter(time => time.start_time || time.end_time)
        )
        .flat()

      if (
        formatedSchedules.filter(
          time =>
            (time.start_time && !time.end_time) ||
            (!time.start_time && time.end_time)
        ).length > 0
      ) {
        this.isSave = true
      } else {
        if (
          formatedSchedules.filter(time => time.start_time >= time.end_time)
            .length == 0
        ) {
          const status = await this.addSchedules({
            id: this.$route.params.id,
            pkg: {
              schedules: formatedSchedules.map(obj => {
                delete obj.is_final
                return obj
              })
            }
          })
          if (status) {
            this.$router.push({
              name: 'department-list',
              query: {isCreateSuccess: true, snackTitle: 'added schedules'}
            })
          } else {
            this.isCreateFail = true
          }
        }
      }
    },
    ...mapActions('department', [
      'resetState',
      'addSchedules',
      'setSchedules',
      'setOrgInfo',
      'setDepartmentId'
    ])
  }
}
</script>

<style scoped>
.symptomatic-box-error {
  color: #ff5252 !important;
}
.v-input--is-readonly {
  background: #ecf0f3 !important;
}
</style>
