<template>
  <v-row align="center" justify="start" class="mt-0 px-2 mb-6">
    <v-col t-data="info-input">
      <p class="edit-field-label sub-body-bold" t-data="info-label">
        Start Time <span class="danger500Color--text"></span>
      </p>
      <v-select
        class="can-disable-selector"
        placeholder="Select Start Time"
        :items="timeSlots"
        outlined
        required
        color="primary500Color"
        dense
        item-text="text"
        item-value="value"
        :hide-details="true"
        :clearable="timeslotObj.is_final ? true : false"
        v-model="timeslotObj.start_time"
        append-icon="mdi-clock-outline"
      >
      </v-select>
      <p
        class="value-message edit-error-message small mb-0"
        style="visibility: hidden;"
      >
        Start time must be less than start time
      </p>
    </v-col>
    <v-col t-data="info-input">
      <span class="edit-field-label sub-body-bold" t-data="info-label">
        End Time
      </span>
      <v-select
        class="can-disable-selector error--text"
        placeholder="Select End Time"
        :items="timeSlots"
        outlined
        color="primary500Color"
        dense
        item-text="text"
        item-value="value"
        :hide-details="true"
        :clearable="timeslotObj.is_final ? true : false"
        v-model="timeslotObj.end_time"
        append-icon="mdi-clock-outline"
        :rules="[endTimeValidation]"
      >
      </v-select>
      <p
        class="value-message edit-error-message small mb-0"
        :style="{
          visibility:
            timeslotObj.end_time <= timeslotObj.start_time &&
            timeslotObj.start_time &&
            timeslotObj.end_time
              ? 'visible'
              : 'hidden'
        }"
      >
        Endtime must be greater than start time
      </p>
    </v-col>

    <v-col t-data="info-input">
      <span class="edit-field-label sub-body-bold" t-data="info-label">
        Remark
      </span>
      <v-text-field
        class="can-disable-selector"
        outlined
        dense
        :maxlength="25"
        :hide-details="true"
        v-model="timeslotObj.remark"
        :clearable="timeslotObj.is_final ? true : false"
      ></v-text-field>
      <p class="value-message small mb-0 secondaryTextColor--text">
        Maximum 25 characters
      </p>
    </v-col>

    <v-col t-data="info-input" class="mt-0">
      <p class="edit-field-label sub-body-bold" t-data="info-label">
        Tooltip <span class="danger500Color--text"></span>
      </p>
      <v-text-field
        class="can-disable-selector"
        outlined
        dense
        :maxlength="70"
        :hide-details="true"
        v-model="timeslotObj.tooltip"
        :clearable="timeslotObj.is_final ? true : false"
      ></v-text-field>
      <p class="value-message small mb-0 secondaryTextColor--text">
        Maximum 70 characters
      </p>
    </v-col>

    <v-col cols="1" t-data="info-input">
      <span class="edit-field-label sub-body-bold" t-data="info-label"></span>
      <v-icon
        v-if="timeslotObj.is_final"
        right
        class="add-icon"
        @click="addNewSchedule()"
        :disabled="
          timeslotObj.start_time &&
          timeslotObj.end_time &&
          timeslotObj.end_time > timeslotObj.start_time
            ? false
            : true
        "
        >mdi-plus-circle-outline</v-icon
      >
      <v-icon v-else right @click="removeSchedule()"
        >mdi-trash-can-outline</v-icon
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TimeSlot',
  data() {
    return {
      timeSlots: [
        {value: '00:00:00', text: '00:00'},
        {value: '01:00:00', text: '01:00'},
        {value: '02:00:00', text: '02:00'},
        {value: '03:00:00', text: '03:00'},
        {value: '04:00:00', text: '04:00'},
        {value: '05:00:00', text: '05:00'},
        {value: '06:00:00', text: '06:00'},
        {value: '07:00:00', text: '07:00'},
        {value: '08:00:00', text: '08:00'},
        {value: '09:00:00', text: '09:00'},
        {value: '10:00:00', text: '10:00'},
        {value: '11:00:00', text: '11:00'},
        {value: '12:00:00', text: '12:00'},
        {value: '13:00:00', text: '13:00'},
        {value: '14:00:00', text: '14:00'},
        {value: '15:00:00', text: '15:00'},
        {value: '16:00:00', text: '16:00'},
        {value: '17:00:00', text: '17:00'},
        {value: '18:00:00', text: '18:00'},
        {value: '19:00:00', text: '19:00'},
        {value: '20:00:00', text: '20:00'},
        {value: '21:00:00', text: '21:00'},
        {value: '22:00:00', text: '22:00'},
        {value: '23:00:00', text: '23:00'}
      ]
    }
  },
  methods: {},
  props: {
    timeslotObj: {
      type: Object
    },
    removeSchedule: {
      type: Function,
      required: false
    },
    addNewSchedule: {
      type: Function,
      required: false
    }
  },
  computed: {
    endTimeValidation() {
      return () => {
        if (
          this.timeslotObj.end_time <= this.timeslotObj.start_time &&
          this.timeslotObj.start_time &&
          this.timeslotObj.end_time
        ) {
          return 'End time must be after start time'
        }
        return true
      }
    }
  }
}
</script>
<style scoped>
.add-icon {
  color: var(--primary-500-color);
}
.validation-error {
  color: #ff5252 !important;
}
</style>
